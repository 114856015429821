/* eslint-disable max-lines */
import { DepartmentOptionsPerLocation } from '@app/reducers/orm/location/location.model';
import find from 'lodash-es/find';
import keyBy from 'lodash-es/keyBy';
import some from 'lodash-es/some';
import sortBy from 'lodash-es/sortBy';
import zipObject from 'lodash-es/zipObject';
import { createSelector } from 'reselect';

import { getAccountSubscription } from '../../reducers/account/account.service';
import { PermissionState } from '../../reducers/auth/auth.model';
import {
  getEmployeeTeamDepartments,
  getEmployeeTeamDepartmentsWithoutFlexpool,
  getPermissionState,
  hasAllPermissions,
  hasPermission,
  hasPermissionForAllDepartments,
  PermissionCheck,
  permissionDepartments,
} from '../../reducers/auth/permission.helper';
import { getContractTypeIds } from '../../reducers/orm/contract-type/contract-type.service';
import { ContractModel } from '../../reducers/orm/contract/contract.model';
import {
  canViewContracts,
  contractPeriodFilter,
  getActiveContracts,
  getContracts,
  getPeriodContracts,
} from '../../reducers/orm/contract/contract.service';
import { getDepartments } from '../../reducers/orm/department/department.service';
import {
  EmployeeModel,
  EmployeeWithActiveContractAndDepartments,
  UsersGroup,
} from '../../reducers/orm/employee/employee.model';
import {
  employeeInDepartment,
  employeeIsActive,
  getEmployeeEntities,
  getEmployees,
  getSelectedEmployees,
  modelHasEmployee,
} from '../../reducers/orm/employee/employee.service';
import { getDepartmentOptionsPerLocation, getLocationEntities } from '../../reducers/orm/location/location.service';
import { getPermissionGroupIds } from '../../reducers/orm/permission-group/permission-group.service';
import { getTeamEntities } from '../../reducers/orm/team/team.service';
import { getEmployeeFilters } from '../../reducers/page-filters/page-filters.service';
import { getSelectedDepartmentIds } from '../../reducers/selected-departments/selected-departments.service';
import { combinedFilter, convertArrayToObject, filterDeleted, mapEntity } from '../../reducers/shared/entity.helper';
import { getArrayIntersection } from '../../shared/array.helper';
import { hasAtleastSubscriptionPlan } from '../../shared/subscription-plan/subscription-plan.directive';
import { PlanType } from '../+reports/shared/subscriptions/subscription.model';
import { isAnyFilterActive } from '../shared/filters-toggle/filters-toggle.helper';
import {
  employeeListSearchFilter,
  getSelectedEmployeesId,
  modelEmployeeInList,
} from './../../reducers/orm/employee/employee.service';
import { canViewSkills, getSkillIds } from './../../reducers/orm/skill/skill.service';

export const getSelectedEmployeeId = createSelector(
  getEmployeeFilters,
  (employeeFilters) => employeeFilters.selectedEmployee,
);

export const getSelectedEmployeePage = createSelector(
  getEmployeeFilters,
  (employeeFilters) => employeeFilters.selectedPage,
);

export const getEmployeeSearch = createSelector(getEmployeeFilters, (employeeFilters) => employeeFilters.search);

const getEmployeeStatus = createSelector(getEmployeeFilters, (employeeFilters) => employeeFilters.status);

const getSelectedContractTypes = createSelector(
  getEmployeeFilters,
  getContractTypeIds,
  canViewContracts,
  (employeeFilters, contractTypeIds, canViewContracts) => {
    const contractFilter =
      employeeFilters && employeeFilters.hasOwnProperty('contract') ? employeeFilters.contract : {};
    const allContracts = ['noContract', ...contractTypeIds];

    return zipObject(
      allContracts,
      allContracts.map((id) => {
        if (!canViewContracts || !contractFilter.hasOwnProperty(id)) {
          return true;
        }

        return contractFilter[id];
      }),
    );
  },
);

const getSelectedEmployeeGroups = createSelector(
  getEmployeeFilters,
  getPermissionGroupIds,
  (employeeFilters, permissionGroupIds) => {
    const groupFilter = employeeFilters && employeeFilters.hasOwnProperty('group') ? employeeFilters.group : {};

    return zipObject(
      permissionGroupIds,
      permissionGroupIds.map((id) => (groupFilter.hasOwnProperty(id) ? groupFilter[id] : true)),
    );
  },
);

const getSelectedEmployeeSkills = createSelector(
  getEmployeeFilters,
  getSkillIds,
  canViewSkills,
  (employeeFilters, skillIds, canViewSkills) => {
    if (!canViewSkills || !employeeFilters || !employeeFilters.skills) {
      return [];
    }

    return getArrayIntersection(skillIds, employeeFilters.skills ? employeeFilters.skills : []);
  },
);

export const getEmployeeSidebarFilters = createSelector(
  getSelectedContractTypes,
  getSelectedEmployeeGroups,
  getEmployeeStatus,
  getSelectedEmployeeSkills,
  (contract, group, status, skills) => ({ contract, group, status, skills }),
);

export const isAnyEmployeeSidebarFilterActive = createSelector(getEmployeeSidebarFilters, (filters) =>
  isAnyFilterActive(filters),
);

export const canViewUserDetails = createSelector(
  getSelectedDepartmentIds,
  getPermissionState,
  (selectedDepartmentIds, permissionState) =>
    hasPermissionForAllDepartments(
      {
        permissions: 'View user details',
        departments: selectedDepartmentIds,
      },
      permissionState,
    ),
);

export const canViewPermissions = createSelector(
  getSelectedDepartmentIds,
  getPermissionState,
  (selectedDepartmentIds, permissionState) =>
    hasPermissionForAllDepartments(
      {
        permissions: 'Edit user permissions',
        departments: selectedDepartmentIds,
      },
      permissionState,
    ),
);

export const canViewInactiveUsers = createSelector(
  getSelectedDepartmentIds,
  getPermissionState,
  (selectedDepartmentIds, permissionState) =>
    hasPermission(
      {
        permissions: 'View inactive users',
        departments: selectedDepartmentIds,
      },
      permissionState,
    ),
);

export const canEditContracts = createSelector(
  getSelectedDepartmentIds,
  getPermissionState,
  (selectedDepartmentIds, permissionState) =>
    hasPermission(
      {
        permissions: 'Edit contracts',
        departments: selectedDepartmentIds,
      },
      permissionState,
    ),
);

export const canEditPlusMinHours = createSelector(
  getSelectedDepartmentIds,
  getPermissionState,
  (selectedDepartmentIds, permissionState) =>
    hasPermission(
      {
        permissions: 'Edit plus min hours',
        departments: selectedDepartmentIds,
      },
      permissionState,
    ),
);

export const canEditUsers = createSelector(
  getSelectedDepartmentIds,
  getPermissionState,
  (selectedDepartmentIds, permissionState) =>
    hasPermission(
      {
        permissions: 'Edit users',
        departments: selectedDepartmentIds,
      },
      permissionState,
    ),
);

export const canCreateAbsentee = createSelector(
  getSelectedDepartmentIds,
  getPermissionState,
  (selectedDepartmentIds, permissionState) =>
    hasAllPermissions(
      {
        permissions: ['Create absentee', 'Approve absentee'],
        departments: selectedDepartmentIds,
      },
      permissionState,
    ),
);

export const canEditVacationsHours = createSelector(
  getSelectedDepartmentIds,
  getPermissionState,
  (selectedDepartmentIds, permissionState) =>
    hasPermission(
      {
        permissions: 'Edit vacation hours',
        departments: selectedDepartmentIds,
      },
      permissionState,
    ),
);

export const canAdjustTimeOffBalance = createSelector(
  getSelectedDepartmentIds,
  getPermissionState,
  (selectedDepartmentIds, permissionState) =>
    hasPermission(
      {
        permissions: 'Adjust time off balances',
        departments: selectedDepartmentIds,
      },
      permissionState,
    ),
);

export const getSelectedEmployee = createSelector(
  getSelectedEmployeeId,
  getEmployeeEntities,
  getEmployeeTeamDepartments,
  getEmployeeTeamDepartmentsWithoutFlexpool,
  (selectedEmployeeId, employeeEntities, employeeTeamDepartments, employeeTeamDepartmentsWithoutFlexpool) => {
    const employee = mapEntity(selectedEmployeeId, employeeEntities);

    if (!employee || employee.anonymized) {
      return;
    }

    return {
      ...employee,
      departments: employeeTeamDepartmentsWithoutFlexpool[employee.id],
      departmentsWithFlexpool: employeeTeamDepartments[employee.id],
    };
  },
);

export const getSelectedEmployeeFiles = createSelector(getSelectedEmployee, (employee: EmployeeModel) => {
  if (!employee.Files) {
    return [];
  }

  return sortBy(employee.Files, 'created');
});

export const getSelectedEmployeeFilePermissions = (employeeId: string) =>
  createSelector(getPermissionState, getEmployeeTeamDepartments, (permissionState, employeeTeamDepartments) => {
    const check: PermissionCheck = {
      userId: employeeId,
      departments: employeeTeamDepartments[employeeId],
      permissions: [],
    };

    return {
      userId: employeeId,
      canView: hasPermission(
        { ...check, permissions: ['View user uploads', 'View own public uploads'] },
        permissionState,
      ),
      canAdd: hasPermission({ ...check, permissions: ['Add user uploads', 'Add own user uploads'] }, permissionState),
      canEdit: hasPermission(
        { ...check, permissions: ['Edit user uploads', 'Edit own user uploads'] },
        permissionState,
      ),
      canDelete: hasPermission(
        { ...check, permissions: ['Delete user uploads', 'Delete own user uploads'] },
        permissionState,
      ),
    };
  });

export const getSelectedEmployeeNotes = createSelector(getSelectedEmployee, (employee: EmployeeModel) => {
  if (!employee.Notes) {
    return [];
  }

  return sortBy(employee.Notes, 'date');
});

export const getSelectedEmployeeNotePermissions = (employeeId: string) =>
  createSelector(getPermissionState, getEmployeeTeamDepartments, (permissionState, employeeTeamDepartments) => {
    const check = {
      userId: employeeId,
      departments: employeeTeamDepartments[employeeId],
    };

    return {
      userId: employeeId,
      canView: hasPermission({ ...check, permissions: ['View user notes'] }, permissionState),
      canEdit: hasPermission({ ...check, permissions: ['Edit user notes'] }, permissionState),
      canDelete: hasPermission({ ...check, permissions: ['Delete user notes'] }, permissionState),
    };
  });

export const getSelectedEmployeeContracts = createSelector(
  getSelectedEmployeeId,
  getContracts,
  (employeeId: string, contracts: ContractModel[]) => contracts.filter(modelHasEmployee(employeeId)),
);

export const getSelectedEmployeesContractsForPeriod = (date: string) =>
  createSelector(getSelectedEmployeesId, getContracts, (employeeIds: string[], contracts: ContractModel[]) =>
    contracts.filter(modelEmployeeInList(employeeIds)).filter(contractPeriodFilter(date, date)),
  );

const employeesWithContractsAndDepartments = createSelector(
  getEmployees,
  getActiveContracts,
  getEmployeeTeamDepartments,
  (employees, contracts, employeeTeamDepartments): EmployeeWithActiveContractAndDepartments[] => {
    const activeContractPerEmployee = keyBy(contracts, 'user_id');

    return employees.map((employee) => {
      const isActive = employeeIsActive(employee);

      return {
        ...employee,
        activeContract: isActive ? activeContractPerEmployee[employee.id] : undefined,
        isActive,
        departments: employeeTeamDepartments[employee.id],
      };
    });
  },
);

export const getEmployeesWithActiveContractEntities = createSelector(
  employeesWithContractsAndDepartments,
  (employees: EmployeeWithActiveContractAndDepartments[]) => convertArrayToObject('id', employees),
);
const getSelectedEmployeesWithContractsForDate = (date: string) =>
  createSelector(getSelectedEmployees, getSelectedEmployeesContractsForPeriod(date), (employees, contracts) => {
    const activeContractPerEmployee = keyBy(contracts, 'user_id');

    return employees.map((employee) => {
      const isActive = employeeIsActive(employee);

      return {
        ...employee,
        activeContract: isActive ? activeContractPerEmployee[employee.id] : undefined,
      };
    });
  });

export const getSelectedEmployeesWithContractForBulkCorrectionsForDate = (date: string) =>
  createSelector(
    getSelectedEmployeesWithContractsForDate(date),
    getEmployeeTeamDepartments,
    getPermissionState,
    (employees: EmployeeWithActiveContractAndDepartments[], employeeTeamDepartments, permissionState) =>
      employees.map(mapPermissionsForBulkActions(employeeTeamDepartments, permissionState)),
  );
export const getSelectedEmployeesForBulkActions = createSelector(
  getSelectedEmployees,
  getEmployeeTeamDepartments,
  getPermissionState,
  (employees: EmployeeModel[], employeeTeamDepartments, permissionState) =>
    employees.map(mapPermissionsForBulkActions(employeeTeamDepartments, permissionState)),
);

const mapPermissionsForBulkActions = (employeeTeamDepartments, permissionState) => (employee) => {
  const check = {
    userId: employee.id,
    departments: employeeTeamDepartments[employee.id],
  };
  return {
    ...employee,
    canEditContracts: hasPermission({ ...check, permissions: ['Edit contracts'] }, permissionState),
    canViewUserDetails: hasPermission({ ...check, permissions: ['View user details'] }, permissionState),
    canCreateAbsentee: hasPermission(
      {
        ...check,
        permissions: ['Create absentee', 'Approve absentee'],
      },
      permissionState,
    ),
    canEditUsers: hasPermission({ ...check, permissions: ['Edit users'] }, permissionState),
    canEditVacationsHours: hasPermission({ ...check, permissions: ['Edit vacation hours'] }, permissionState),
    canAdjustTimeOffBalance: hasPermission({ ...check, permissions: ['Adjust time off balances'] }, permissionState),
    canEditPlusMinHours: hasPermission({ ...check, permissions: ['Edit plus min hours'] }, permissionState),
  };
};

export const getBulkSelectedEmployeeContracts = (date) =>
  createSelector(
    getSelectedEmployeesForBulkActions,
    getPeriodContracts(date),
    (employees: EmployeeModel[], contracts: ContractModel[]) =>
      employees.map((employee) => ({
        ...employee,
        contract: find(contracts, ['user_id', employee.id]),
      })),
  );

export const getSelectedEmployeeContractPermissions = createSelector(
  getSelectedEmployeeId,
  getPermissionState,
  getEmployeeTeamDepartments,
  (employeeId, permissionState, employeeTeamDepartments) => {
    const check = {
      userId: employeeId,
      departments: employeeTeamDepartments[employeeId],
    };

    return {
      canView: hasPermission({ ...check, permissions: ['View contracts'] }, permissionState),
      canViewSalary: hasPermission({ ...check, permissions: ['View salary'] }, permissionState),
      canAdd: hasPermission({ ...check, permissions: ['Create contracts'] }, permissionState),
      canEdit: hasPermission({ ...check, permissions: ['Edit contracts'] }, permissionState),
      canDelete: hasPermission({ ...check, permissions: ['Delete contracts'] }, permissionState),
    };
  },
);

export const getSelectedEmployeeEditPermissions = createSelector(
  getSelectedEmployeeId,
  getPermissionState,
  getEmployeeTeamDepartments,
  (employeeId, permissionState, employeeTeamDepartments) => {
    const check = {
      userId: employeeId,
      departments: employeeTeamDepartments[employeeId],
    };

    return {
      canEdit: hasPermission({ ...check, permissions: ['Edit users'] }, permissionState),
    };
  },
);

export const getEmployeeFilterOptions = createSelector(
  getSelectedDepartmentIds,
  getSelectedContractTypes,
  getSelectedEmployeeGroups,
  getSelectedEmployeeSkills,
  canViewContracts,
  canViewPermissions,
  getEmployeeSearch,
  getEmployeeStatus,
  (departmentIds, contractTypeIds, groupIds, skills, viewContracts, viewPermissions, search, status) => ({
    departmentIds,
    contractTypeIds,
    groupIds,
    viewContracts,
    viewPermissions,
    search,
    status,
    skills,
  }),
);

export const getEmployeeAccessDepartmentOptions = createSelector(
  getPermissionState,
  getDepartments,
  getLocationEntities,
  (permissionState, departments, locationsById) => {
    const check: PermissionCheck = {
      permissions: 'Edit user permissions',
      departments: 'any',
    };

    const departmentIds = permissionDepartments(check, permissionState);

    const filteredDepartments = departments.filter((department) => departmentIds.indexOf(department.id) !== -1);

    return filterDeleted(getDepartmentOptionsPerLocation(filteredDepartments, locationsById));
  },
);

export const selectEmployeeAccessPermissionAndTeamsDepartmentOptions = createSelector(
  getPermissionState,
  getDepartments,
  getLocationEntities,
  (
    permissionState,
    departments,
    locationsById,
  ): {
    locations: DepartmentOptionsPerLocation[];
    editOnlyTeamDepartments: string[];
  } => {
    const editPermissionDepartments = permissionDepartments(
      {
        permissions: 'Edit user permissions',
        departments: 'any',
      },
      permissionState,
    );
    const editTeamDepartments = permissionDepartments(
      {
        permissions: 'Edit user teams',
        departments: 'any',
      },
      permissionState,
    );

    //  array of department ids that is editable for permissions or teams
    const departmentIds = editPermissionDepartments.concat(editTeamDepartments);

    const filteredDepartments = departments.filter((department) => departmentIds.indexOf(department.id) !== -1);

    // array of department ids that is only editable for teams and not permisions
    const editOnlyTeamDepartments = editTeamDepartments.filter((id) => !editPermissionDepartments.includes(id));

    return {
      locations: filterDeleted(getDepartmentOptionsPerLocation(filteredDepartments, locationsById)),
      editOnlyTeamDepartments,
    };
  },
);

export const selectEmployeeEditTeamsAccessDepartmentOptions = createSelector(
  getPermissionState,
  getDepartments,
  getLocationEntities,
  (permissionState, departments, locationsById): DepartmentOptionsPerLocation[] => {
    const check: PermissionCheck = {
      permissions: 'Edit user teams',
      departments: 'any',
    };

    const departmentIds = permissionDepartments(check, permissionState);

    const filteredDepartments = departments.filter((department) => departmentIds.indexOf(department.id) !== -1);

    return filterDeleted(getDepartmentOptionsPerLocation(filteredDepartments, locationsById));
  },
);

const employeeStatusFilterFn =
  (statusFilter, permissionState: PermissionState, employeeTeamDepartments) =>
  (employee: EmployeeModel): boolean => {
    const active = employeeIsActive(employee);

    const invited = employee.invited && !employee.verified;
    const notInvited = active && !employee.invited;
    const activeAndVerified = active && employee.verified;

    if (!statusFilter.active && activeAndVerified) {
      return false;
    }

    if (!statusFilter.inactive && !active) {
      return false;
    }

    if (!statusFilter.invited && invited) {
      return false;
    }

    if (!statusFilter.notInvited && notInvited) {
      return false;
    }

    if (
      !active &&
      !hasPermission(
        {
          userId: employee.id,
          permissions: 'View inactive users',
          departments: employeeTeamDepartments[employee.id],
        },
        permissionState,
      )
    ) {
      return false;
    }

    return true;
  };

const employeeContractFilter =
  (selectedContractIds, viewContracts) =>
  (employee: EmployeeModel): boolean => {
    if (!viewContracts) {
      return true;
    }

    if (!employee.activeContract) {
      return selectedContractIds.noContract;
    }

    const contractTypeId = employee.activeContract.contract_type_id;

    return !!selectedContractIds[contractTypeId];
  };

const employeeGroupFilter =
  (viewPermissions: boolean, selectedGroupIds, selectedDepartmentIds: string[]) => (employee: EmployeeModel) => {
    if (!viewPermissions) {
      return true;
    }

    return some(employee.UsersGroup, (usersGroup: UsersGroup) => {
      if (selectedDepartmentIds.indexOf(usersGroup.department_id) === -1) {
        return false;
      }

      return !!selectedGroupIds[usersGroup.group_id];
    });
  };

export const employeeSkillFilter = (selectedSkills) => (employee: EmployeeModel) => {
  // No skills selected: show all employees
  if (selectedSkills.length === 0) {
    return true;
  } else {
    if (!employee.hasOwnProperty('Skill') || employee.Skill.length === 0) {
      // Employee has no skills: don't show employee
      return false;
    } else {
      // Employee has skills: only show employee when all selected skills are present
      const skillIds = employee.Skill.map((skill) => skill.id);
      return selectedSkills.every((skillId) => skillIds.indexOf(skillId) !== -1);
    }
  }
};

const employeeFilterAnonymized = (showAnonymized: boolean) => (employee: EmployeeModel) =>
  employee.anonymized === showAnonymized;

export const getEmployeeList = createSelector(
  employeesWithContractsAndDepartments,
  getEmployeeFilterOptions,
  getTeamEntities,
  getPermissionState,
  getEmployeeTeamDepartments,
  getAccountSubscription,
  (
    employees,
    filterOptions,
    teamEntities,
    permissionState,
    employeeTeamDepartments,
    subscription,
  ): EmployeeWithActiveContractAndDepartments[] => {
    const combinedFilters = [
      employeeInDepartment(filterOptions.departmentIds, teamEntities, null, true),
      employeeStatusFilterFn(filterOptions.status, permissionState, employeeTeamDepartments),
      employeeContractFilter(filterOptions.contractTypeIds, filterOptions.viewContracts),
      employeeGroupFilter(filterOptions.viewPermissions, filterOptions.groupIds, filterOptions.departmentIds),
      employeeListSearchFilter(filterOptions.search),
      employeeFilterAnonymized(false),
    ];

    if (hasAtleastSubscriptionPlan(PlanType.EARLY_ADOPTER, subscription)) {
      combinedFilters.push(employeeSkillFilter(filterOptions.skills));
    }

    return combinedFilter(employees, ...combinedFilters);
  },
);
